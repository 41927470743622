const ITEM_SELECTOR = '.search-results-item';
export default class SearchResultKeys {
    constructor(input, results, itemSelector = ITEM_SELECTOR, onSelect) {
        this.input = input;
        this.results = results;
        this.itemSelector = itemSelector;
        this.onSelect = onSelect;
        input.addEventListener("keyup", this.isHandledKeyPressed.bind(this));
        input.addEventListener("keypress", this.isHandledKeyPressed.bind(this));
        input.addEventListener("keydown", this.onKeyPress.bind(this));
    }
    isHandledKeyPressed(event) {
        this.validateSelection();
        if (!['ArrowDown', 'ArrowUp', 'Home', 'End', 'PageUp', 'PageDown', 'Enter', 'Escape'].includes(event.key)) {
            return false;
        }
        if (event.key === 'Enter') {
            if (this.getSelectedItem()) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.onSelect(this.count() === 1 ? this.getFirstItem() : this.getSelectedItem());
            return false;
        }
        if (event.shiftKey || event.altKey || event.ctrlKey || event.metaKey) {
            return false;
        }
        if (this.isEmpty()) {
            return false;
        }
        event.preventDefault();
        event.stopPropagation();
        return true;
    }
    onKeyPress(event) {
        if (!this.isHandledKeyPressed(event)) {
            return;
        }
        this.select(this.parseKey(this.getSelectedItem(), event.key));
    }
    parseKey(item, key) {
        var _a, _b;
        switch (key) {
            case 'ArrowDown':
                return (_a = this.getNextItem()) !== null && _a !== void 0 ? _a : this.getLastItem();
            case 'ArrowUp':
                return (_b = this.getPreviousItem()) !== null && _b !== void 0 ? _b : this.getFirstItem();
            case 'Home':
                return this.getFirstItem();
            case 'End':
                return this.getLastItem();
            case 'PageUp':
                return SearchResultKeys.multistep(item !== null && item !== void 0 ? item : this.getLastItem(), this.calculateNumberOfLines(), item => item.previousElementSibling);
            case 'PageDown':
                return SearchResultKeys.multistep(item !== null && item !== void 0 ? item : this.getFirstItem(), this.calculateNumberOfLines(), item => item.nextElementSibling);
        }
    }
    getSelectedItem() {
        return this.results.querySelector(`${this.itemSelector}[data-selected]`);
    }
    isEmpty() {
        return !this.getFirstItem();
    }
    getFirstItem() {
        return this.results.querySelector(`${this.itemSelector}:first-child`);
    }
    getLastItem() {
        return this.results.querySelector(`${this.itemSelector}:last-child`);
    }
    getSelectedItems() {
        return this.results.querySelectorAll(`${this.itemSelector}[data-selected]`);
    }
    calculateNumberOfLines() {
        const item = this.getFirstItem();
        if (!item) {
            return 0;
        }
        return Math.floor(this.results.getBoundingClientRect().height / item.getBoundingClientRect().height);
    }
    select(item) {
        this.lastSelected = item;
        if (!item) {
            return false;
        }
        this.deselect(item);
        item.dataset.selected = '';
        SearchResultKeys.scrollIntoView(item);
        return true;
    }
    static multistep(start, steps, callback) {
        let current = start;
        while (steps--) {
            const result = callback(current);
            if (!result) {
                return current;
            }
            current = result;
        }
        return current;
    }
    static scrollIntoView(item) {
        item.scrollIntoView({
            block: "nearest",
            behavior: 'smooth',
        });
    }
    deselect(exclude) {
        let deselected = false;
        this.getSelectedItems().forEach((item) => {
            if (item !== exclude) {
                delete item.dataset.selected;
            }
            else {
                deselected = true;
            }
        });
        return deselected;
    }
    getNextItem() {
        const selected = this.getSelectedItem();
        return selected ? selected.nextElementSibling : this.getFirstItem();
    }
    getPreviousItem() {
        const selected = this.getSelectedItem();
        return selected ? selected.previousElementSibling : this.getLastItem();
    }
    count() {
        return this.results.querySelectorAll(this.itemSelector).length;
    }
    validateSelection() {
        this.deselect(this.lastSelected);
        if (this.lastSelected) {
            SearchResultKeys.scrollIntoView(this.lastSelected);
        }
    }
}
