export default function initJumpTopButton() {
    if (!window.IntersectionObserver) {
        return;
    }
    const toTopButton = createElement();
    toTopButton.addEventListener('click', (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
    });
    const observer = new IntersectionObserver(function (entries) {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                toTopButton.classList.remove('show');
            }
            else {
                toTopButton.classList.add('show');
            }
        }
    });
    observer.observe(document.querySelector('.header'));
}
function createElement() {
    const button = document.createElement('button');
    button.classList.add('to-top');
    button.innerText = 'Наверх';
    document.body.appendChild(button);
    return button;
}
