const activeTabClassName = 'tab-link--active';
function init(tabsParent) {
    tabsParent.querySelectorAll('.tab-link').forEach(el => {
        el.addEventListener('click', e => {
            e.preventDefault();
            onClick(el);
            history.pushState(el.innerText, `${el.innerText} бренды`, el.href);
        });
    });
    window.addEventListener('popstate', event => activateByTitle(event.state));
    document.addEventListener('DOMContentLoaded', activateActiveElement);
    function activateByTitle(title) {
        if (document.location.hash && title === null) {
            return;
        }
        if (title === null) {
            title = 'Все';
        }
        for (const el of Array.from(tabsParent.querySelectorAll('.tab-link'))) {
            if (el.innerText !== title) {
                continue;
            }
            onClick(el);
            break;
        }
    }
    function activateActiveElement() {
        for (const el of Array.from(tabsParent.querySelectorAll('.tab-link'))) {
            if (!el.classList.contains(activeTabClassName)) {
                continue;
            }
            onClick(el, true);
            break;
        }
    }
    function onClick(el, force = false) {
        if (el.classList.contains(activeTabClassName) && !force) {
            return;
        }
        tabsParent.querySelectorAll(`.${activeTabClassName}`)
            .forEach(el => el.classList.remove(activeTabClassName));
        el.classList.add(activeTabClassName);
        if (el.innerText === 'Все') {
            reset();
        }
        else {
            updateCategory(el.innerText);
        }
    }
    function updateCategory(category) {
        for (const el of Array.from(document.querySelectorAll(`.abc-nav__item:not([data-category~="${category}"]):not(.hidden)`))) {
            el.classList.add('hidden');
        }
        for (const el of Array.from(document.querySelectorAll(`.abc-nav__item.hidden[data-category~="${category}"]`))) {
            el.classList.remove('hidden');
        }
        updateLettersVisibility();
    }
    function reset() {
        for (const el of Array.from(document.querySelectorAll('.abc-nav__item.hidden'))) {
            el.classList.remove('hidden');
        }
        updateLettersVisibility();
    }
    function updateLettersVisibility() {
        for (const letterBlock of Array.from(document.querySelectorAll('.abc-nav__block'))) {
            const letter = letterBlock.querySelector('.abc-nav__letter').innerText.trim();
            const letterLink = document.querySelector(`.letters-nav__link[href="#${letter}"]`);
            if (letterBlock.querySelector('.abc-nav__item:not(.hidden)')) {
                letterBlock.classList.remove('hidden');
                letterLink.classList.remove('disabled');
            }
            else {
                letterBlock.classList.add('hidden');
                letterLink.classList.add('disabled');
            }
        }
    }
}
const filter = document.querySelector('.abc-nav__filter');
if (filter) {
    init(filter);
}
