import '../vendor/sentry-lazy.js';
const environments = {
    'www.orental.ru': 'prod',
    'dev.orental.ru': 'test',
};
window.Sentry.onLoad(function () {
    var _a;
    window.Sentry.init({
        environment: (_a = environments[document.location.hostname]) !== null && _a !== void 0 ? _a : 'dev',
        denyUrls: [
            'bitrix',
            /https?:\/\/(?!((www|dev).)?orental.(ru|devel|home.polosatus.ru))/,
            // Chrome extensions
            /extensions\//i,
            /^chrome(-extension)?:\/\//i,
        ],
        beforeSend(event, hint) {
            var _a, _b, _c;
            const originalException = hint.originalException;
            let message;
            // @ts-ignore
            if (window.document.documentMode) {
                // ignore IE
                return null;
            }
            if (typeof originalException === 'string') {
                message = originalException;
            }
            // @ts-ignore
            if (typeof (originalException === null || originalException === void 0 ? void 0 : originalException.message) === 'string') {
                // @ts-ignore
                message = originalException.message;
            }
            if (!message) {
                message = (_c = (_b = (_a = event === null || event === void 0 ? void 0 : event.exception) === null || _a === void 0 ? void 0 : _a.values[0]) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : 'Unknown';
            }
            if (message === "Timeout" || message === 'Syntax error in regular expression') {
                return null;
            }
            if (event.exception && event.exception.values) {
                for (const exception of event.exception.values) {
                    if (exception.value.includes('window.yandex.sovetnik')) {
                        return null;
                    }
                    if (filterBitrixErrors(exception.stacktrace)) {
                        return null;
                    }
                }
            }
            if (isBurstProtectionActive()) {
                return null;
            }
            return event;
        }
    });
});
let lastEventTime;
let burstHits = 0;
let burstProtectionActive = false;
const MAX_BURST_EVENTS = 3;
function isBurstProtectionActive() {
    if (burstProtectionActive) {
        return true;
    }
    if (lastEventTime) {
        const now = new Date;
        if (now.getTime() - lastEventTime.getTime() < 3000) {
            burstHits++;
            if (burstHits >= MAX_BURST_EVENTS) {
                burstProtectionActive = true;
            }
        }
        else {
            lastEventTime = new Date();
            burstHits = 1;
        }
    }
    else {
        lastEventTime = new Date();
        burstHits = 1;
    }
    return false;
}
function filterBitrixErrors(stacktrace) {
    var _a;
    const re = new RegExp('bitrix|(/|^)crm/|chrome-extension');
    return !!((_a = stacktrace === null || stacktrace === void 0 ? void 0 : stacktrace.frames) === null || _a === void 0 ? void 0 : _a.find(({ filename, module }) => {
        return filename && filename.match(re) || module && module.match(re);
    }));
}
