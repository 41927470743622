import $ from '../vendor/dom7';
$('body').on('click', '.readmore__link', function (e) {
    const $this = $(this);
    const $body = $this.parents('.readmore__root').find('.readmore__body');
    e.preventDefault();
    if (!$body.hasClass('readmore__body--open')) {
        $body.css('max-height', `${$body[0].scrollHeight}px`);
        $body.addClass('readmore__body--open');
        $this.data('text', $this.text());
        $this.text('Скрыть');
    }
    else {
        $body.css('max-height', '');
        $body.removeClass('readmore__body--open');
        $this.text($this.data('text'));
    }
});
