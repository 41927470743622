const banner = document.querySelector('.reg-teas');
const timeout = 7500;
const storeKeyName = 'reg-banner-hidden';
function show() {
    banner.classList.add('active');
}
function close() {
    banner.classList.remove('active');
    setTimeout(remove, 1000);
    try {
        localStorage.setItem(storeKeyName, 'true');
    }
    catch (_a) {
    }
}
function remove() {
    banner.remove();
}
function isHidden() {
    try {
        return !!localStorage.getItem(storeKeyName);
    }
    catch (_a) {
        return true;
    }
}
export default function () {
    if (!banner) {
        return;
    }
    if (isHidden()) {
        remove();
        return;
    }
    banner.querySelector('.reg-teas__close').addEventListener('click', close);
    setTimeout(show, timeout);
}
