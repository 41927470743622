var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import 'micromodal';
import { showModalFromHtml } from "../../components/modal";
import ajax from "../../components/ajax";
function init() {
    document.querySelectorAll('.repeat-order')
        .forEach((item) => item.addEventListener('click', onClick));
}
function onClick(event) {
    return __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        ajax('/api/cart/repeat_order/', {
            order_id: this.dataset.orderId,
        })
            .then(response => response.json())
            .then((result) => {
            showModalFromHtml(result.html);
            const basketBlock = document.querySelector('.basket_head');
            if (basketBlock) {
                basketBlock.innerHTML = result.basket_block;
            }
        });
    });
}
init();
