import './components/sentry';
import countdown from "./components/countdown";
import '../style/style.scss';
import initJumpTopButton from './components/jump_top_button';
import initLazyLoad from './components/lazy';
import initLetterNavigation from './components/letter_navigation';
import './components/readmore';
import initSearchBar from './components/search_bar';
import './components/scrollto_link';
import { hidePreloader, showPreloader } from "./components/preloader";
import { triggerEvent, triggerProductsLoaded } from "./utils";
import './components/quantity_control';
import { updatePagerHtml } from "./components/pager";
import './screens/order/repeat';
import initRegisterBanner from './components/register_banner';
import initModal from "./components/modal";
import './components/brands_filter';
import $ from './vendor/dom7';
import ajax from "./components/ajax";
import initClickToCopy from "./components/click_to_copy";
document.addEventListener('DOMContentLoaded', function () {
    countdown(document.querySelector('.bnn__countdown'));
    // гамбургер с меню
    $('.btn-main').on('click', function (e) {
        const $nav = $('.h-nav');
        e.preventDefault();
        if ($nav.hasClass('open')) {
            $nav.removeClass('open').addClass('hidden-md-down');
            this.classList.remove('open');
        }
        else {
            $nav.removeClass('hidden-md-down');
            setTimeout(() => $nav.addClass('open'));
            this.classList.add('open');
        }
    });
    // ajax табы
    $(document.body).on('click', '.ajax_tab', function (e) {
        e.preventDefault();
        const td = this;
        const $td = $(td);
        const md = $td.data('md');
        if ($td.is('.tab-link--active')) {
            return;
        }
        $('.ajax_tab').removeClass('tab-link--active');
        $td.addClass('tab-link--active');
        showPreloader();
        if (window.filterStatus !== undefined) {
            triggerEvent(document, 'tab:change', { code: md });
            return;
        }
        history.pushState('', '', md !== 'ALL' ? '?MD=' + md : './');
        ajax('/ajax/ajax_tab.php', {
            MD: md,
            id: $td.data('id'),
            type: $td.parents('.tab-nav').data('type'),
            MODE: $td.data('mode')
        })
            .then(response => response.text())
            .then(function (data) {
            var _a;
            let $element;
            $element = $('.ajax_tab_page');
            if (!$element.length) {
                $element = $('.catalog_items');
            }
            (_a = document.querySelector('.pager')) === null || _a === void 0 ? void 0 : _a.remove();
            $element.html(data);
            updatePagerHtml();
            triggerProductsLoaded();
            $(document).trigger('filter:load');
            highlightFavorites();
        })
            .finally(() => hidePreloader());
    });
    // сердечко на товарах
    $('body').on('click', '.to-favorite', function (e) {
        const id = parseInt($(this).data('id'));
        e.preventDefault();
        this.classList.toggle('active');
        if (window.fav.indexOf(id) !== -1) {
            $(this).removeClass('in').removeClass('active');
            delete window.fav[window.fav.indexOf(id)];
        }
        else {
            $(this).addClass('in').addClass('active');
            window.fav.push(id);
            window.vkEvent(process.env.VK_PRICE_LIST_ID, 'add_to_wishlist', { products: [{ id: id }] });
        }
        if (window.fav.length > 0) {
            $('.favorits-info').show();
            $('.favorits-info>.favorits-info__value').html(window.fav.length.toString());
        }
        else {
            $('.favorits-info').hide();
        }
        showPreloader();
        ajax('/ajax/favorite.php', { id })
            .finally(() => hidePreloader());
    });
    highlightFavorites();
});
// подсветка избранных сердечек
function highlightFavorites() {
    let count = 0;
    if (typeof window['fav'] === 'undefined') {
        return;
    }
    for (const id of window.fav) {
        if (id > 0) {
            $(`.to-favorite[data-id="${id}"]`).addClass('in active');
            count++;
        }
    }
    if (count) {
        $('.favorits-info').show();
        $('.favorits-info>.favorits-info__value').html(count.toString());
    }
    else {
        $('.favorits-info').hide();
    }
}
document.addEventListener('products_loaded', highlightFavorites);
initLazyLoad();
initModal();
initRegisterBanner();
initLetterNavigation();
initSearchBar();
initJumpTopButton();
initClickToCopy('.copy-click');
