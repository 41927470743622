var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { triggerProductsLoaded } from "../utils";
import scrollToElement from "./scrollto";
import SearchResultKeys from "./search_result_keys";
const URL = '/ajax/search';
export class SearchBar {
    constructor(searchBar, searchResults) {
        this.searchBar = searchBar;
        this.searchResults = searchResults;
        this.shadowCache = document.createElement('div');
        this.lastValue = '';
        this.cache = {};
        this.suggestionItems = {};
        this.notFound = '';
        this.timeoutId = 0;
        this.searchResultsList = searchResults.querySelector('.search-result__list');
        this.lastValue = searchBar.value.trim();
        this.keyHandler = new SearchResultKeys(searchBar, searchResults, '.search-result__item', this.onselect.bind(this));
        this.bindEvents();
        SearchBar.setAppHeight();
    }
    bindEvents() {
        const form = this.searchBar.closest('form');
        if (form) {
            form.addEventListener('submit', this.onsubmit.bind(this));
        }
        this.searchBar.addEventListener('keyup', this.onkeyup.bind(this));
        this.searchBar.addEventListener('input', this.onkeyup.bind(this));
        this.searchBar.addEventListener('blur', this.onblur.bind(this));
        this.searchBar.addEventListener('focus', this.onfocus.bind(this));
        this.searchBar.addEventListener('click', this.onfocus.bind(this));
        this.searchResults.addEventListener('focus', this.show.bind(this));
        this.searchResults.addEventListener('click', this.show.bind(this));
        window.addEventListener('resize', SearchBar.setAppHeight);
    }
    static setAppHeight() {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
    hide() {
        this.searchResults.style.display = 'none';
    }
    show() {
        clearTimeout(this.timeoutId);
        this.searchResults.style.display = 'block';
    }
    onfocus() {
        this.show();
        if (screen.width <= 768) {
            scrollToElement(this.searchBar, -12);
        }
        this.onkeyup();
    }
    onblur() {
        this.timeoutId = window.setTimeout(this.hide.bind(this), 200);
    }
    onkeyup(event) {
        const txt = this.searchBar.value.trim().toLowerCase();
        if (event && !event.defaultPrevented && txt !== this.lastValue) {
            this.keyHandler.deselect();
        }
        this.lastValue = txt;
        if (txt.length < 2) {
            this.searchResultsList.querySelectorAll('.search-result__item').forEach(el => this.shadowCache.appendChild(el));
            return;
        }
        if (this.cache[txt] === 'requested') {
            return;
        }
        if (this.cache[txt] !== undefined) {
            // @ts-ignore see above condition
            this.updateFromList(this.cache[txt]);
            return;
        }
        if (this.notFound && txt.startsWith(this.notFound)) {
            return;
        }
        this.makeRequest(txt);
    }
    onsubmit(event) {
        if (this.notFound) {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            return false;
        }
        if (this.searchResultsList.querySelectorAll('.search-result__item').length !== 1) {
            return;
        }
        const link = this.searchResultsList.querySelector('a');
        if (link) {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            link.click();
        }
    }
    onselect(item) {
        var _a;
        if (item) {
            (_a = item.querySelector('a')) === null || _a === void 0 ? void 0 : _a.click();
        }
        else {
            this.onsubmit();
        }
    }
    makeRequest(query) {
        return __awaiter(this, void 0, void 0, function* () {
            this.cache[query] = 'requested';
            const data = new FormData();
            data.append('query', query);
            const html = yield fetch(URL, {
                method: 'POST',
                credentials: 'same-origin',
                cache: 'no-cache',
                body: data
            }).then((response) => response.text())
                .catch(() => {
                delete this.cache[query];
            });
            if (!html) {
                return;
            }
            if (!this.updateHtml(query, html)) {
                this.notFound = query;
            }
            else {
                this.notFound = '';
            }
        });
    }
    updateHtml(query, html) {
        const list = this.collectElements(html);
        this.cache[query] = list;
        const result = this.updateFromList(list);
        triggerProductsLoaded();
        return result;
    }
    updateFromList(list) {
        this.hideNotIncludedItems(list);
        let lastElement;
        list.forEach(id => {
            this.searchResultsList.insertBefore(this.suggestionItems[id], lastElement === null || lastElement === void 0 ? void 0 : lastElement.nextSibling);
            lastElement = this.suggestionItems[id];
        });
        return !(list.length === 1 && list.includes(0));
    }
    collectElements(html) {
        let list = [];
        const div = document.createElement('div');
        div.innerHTML = html;
        div.querySelectorAll('.search-result__item').forEach((el) => {
            const id = el.dataset.id;
            if (!id) {
                return;
            }
            list.push(parseInt(id));
            if (this.suggestionItems[id] !== undefined) {
                return;
            }
            this.suggestionItems[id] = el;
        });
        return list;
    }
    hideNotIncludedItems(list) {
        this.searchResultsList.querySelectorAll('.search-result__item').forEach((el) => {
            const elementId = el.dataset.id;
            if (!elementId || list.includes(parseInt(elementId))) {
                return;
            }
            this.shadowCache.appendChild(el);
        });
    }
}
export default function initSearchBar() {
    new SearchBar(document.getElementById('main-search'), document.querySelector('.search_result'));
}
