var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addLiveEventListener, triggerEvent, triggerProductsLoaded } from '../utils';
import { hidePreloader, showPreloader } from "./preloader";
import scrollToElement from "./scrollto";
if (document.querySelector('.pager[data-ajax="true"]')) {
    addLiveEventListener(document.body, 'click', '.pager__single a, .pager-link', onClick);
    history.replaceState(buildState(), document.title);
    fixViewport();
    fixViewport(300);
    window.addEventListener('popstate', onPopState);
}
function onClick(event) {
    return __awaiter(this, void 0, void 0, function* () {
        const pagerElement = document.querySelector('.pager');
        if ((pagerElement === null || pagerElement === void 0 ? void 0 : pagerElement.dataset.ajax) !== 'true') {
            return;
        }
        const currentPage = parseInt(pagerElement.dataset.currentPage || '1');
        const direct = pagerElement.dataset.direct === 'true';
        const filter = pagerElement.dataset.filter === 'true';
        const url = direct && pagerElement.dataset.baseUrl ? pagerElement.dataset.baseUrl : '/ajax/catalog_page.php';
        let page;
        const isMoreButton = event.target.matches('.pager__single a');
        showPreloader();
        event.preventDefault();
        if (isMoreButton) {
            page = currentPage + 1;
        }
        else if (event.target.dataset.page) {
            page = parseInt(event.target.dataset.page);
        }
        else {
            page = parseInt(event.target.innerText.trim());
        }
        if (filter) {
            triggerEvent(document, 'filter:page', { page, isMoreButton });
            return;
        }
        const params = direct ? {} : window.filterStatus;
        const html = yield fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            body: getFormData(page, params)
        }).then((response) => response.text())
            .catch((error) => {
            hidePreloader();
            window.Sentry.captureException(error);
        });
        if (html) {
            updateHtml(html, !isMoreButton, undefined, isMoreButton);
            pushState(html, page);
        }
    });
}
function onPopState(event) {
    if (!event.state || !event.state.html) {
        return;
    }
    updateHtml(event.state.html, false, event.state.pager);
    fixViewport();
}
function fixViewport(delay = 0) {
    setTimeout(() => {
        const catalogItems = document.querySelector('.catalog_items');
        const boundingRect = catalogItems.getBoundingClientRect();
        if (boundingRect.bottom < 300) {
            scrollToElement(catalogItems, -20);
        }
    }, delay);
}
function pushState(html, page) {
    const query = new URLSearchParams(document.location.search);
    query.delete('page');
    if (page > 1) {
        query.append('page', page.toString());
    }
    history.pushState(buildState(html), document.title, query.toString() ? `?${query.toString()}` : './');
}
function buildState(html) {
    var _a, _b, _c;
    return {
        html: html ? html : (_a = document.querySelector('.catalog_items')) === null || _a === void 0 ? void 0 : _a.innerHTML.trim(),
        pager: html ? undefined : (_c = (_b = document.querySelector('.pager')) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.outerHTML.trim(),
    };
}
export function updateHtml(html, scroll, pagerHtml, append) {
    const catalogItems = document.querySelector('.catalog_items');
    if (!catalogItems) {
        return;
    }
    if (append) {
        catalogItems.insertAdjacentHTML('beforeend', html);
    }
    else {
        triggerEvent(document, 'products_unload');
        catalogItems.innerHTML = html;
    }
    updatePagerHtml(pagerHtml);
    if (scroll) {
        scrollToElement(catalogItems, -20);
    }
    triggerProductsLoaded();
    hidePreloader();
}
export function updatePagerHtml(pagerHtml) {
    var _a, _b;
    const catalogItems = document.querySelector('.catalog_items');
    let pager;
    if (!catalogItems) {
        return;
    }
    if (pagerHtml) {
        const el = document.createElement('div');
        el.innerHTML = pagerHtml.trim();
        pager = el.firstChild;
    }
    else {
        pager = (_a = document.querySelector('.pager')) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (!pager) {
            return;
        }
        pager.remove();
        if (pagerHtml === '') {
            return;
        }
    }
    const wrap = (_b = catalogItems.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement;
    wrap === null || wrap === void 0 ? void 0 : wrap.querySelectorAll('.pager').forEach((el) => { var _a; return (_a = el.parentElement) === null || _a === void 0 ? void 0 : _a.remove(); });
    wrap === null || wrap === void 0 ? void 0 : wrap.appendChild(pager);
}
function getFormData(page, params) {
    const data = {
        page,
        filter: JSON.stringify(params),
    };
    const formData = new FormData();
    for (const name in data) {
        if (data.hasOwnProperty(name)) {
            formData.append(name, data[name]);
        }
    }
    return formData;
}
